import { createContext } from 'react'
import { Product, Auction, Reward } from '../models/product';
import useViewTracking from '../hooks/product/useViewTracking';

interface IProductsContext {
  incrementViewCount: (productIds: string[]) => Promise<void>;
  like: (productId: string | undefined) => Promise<void>;
  
}

const initProps: IProductsContext = {
  incrementViewCount: async (productIds) => {},
  like: async (productId) => {},
}

export const ProductsContext = createContext<IProductsContext>(initProps);

export const ProductsProvider = ({children}: {children: any}) => {
  const {incrementViewCount, like} = useViewTracking();
  return (
    <ProductsContext.Provider value={{incrementViewCount, like}}>
      {children}
    </ProductsContext.Provider>
  )
}