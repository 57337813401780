import styled from "styled-components";

export const ProductWrapper = styled.div`
:hover{
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  transition: all 0.1s ease 0s;
}
margin:5px 0;
border:1px solid #EAEAEA; 
border-radius:8px;

// box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;

clear:both;
li{width:100%;}
.card-wrapper{ height:100%}
.card,.card img{position: relative;
    padding:0;
    height: 240px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  border:0;
  border-radius:8px 8px 0 0 ;
    width: 100% !important;
 
    }
    h3{font-size:.85rem;}
    p{margin-bottom:5px;color:#5A5A5A;font-size:.8rem}
    .price-wrap{border-top:1px solid #EAEAEA;
    li{width:auto;border-right:1px solid #EAEAEA;padding:8px;
      .btn:focus{box-shadow:none}
    }
    }
  

    @media (max-width:991px) {
   
        li{width:100%}
    
  } 
  @media (max-width:600px) {
   
   li{width:100%}

} 
  // padding: 8px;
  // borderRadius: 1rem;
  width: 90%;
  // background: transparent !important;
  // .title {
  //   display: none;
  // }
  // .pdt-cover {
  //   display: flex;
  //   padding: 20px;
  //   padding-top:15px;
  //   width: 100% ;
  //   @media (max-width: 424px) {
  //     width: 96vw !important;
  //   }
  //   background: #fff;
  //   border-radius: 1.5rem;
  //   flex-direction: column;
  //   position: relative;
  //   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  // }
  // h3 {
  //   font-size: 1rem;
  // }
  // p {
  //   color: #656565;
  //   font-size: 0.8rem;
  //   margin: 0;
  // }
  // .pdt-name{
  //   font-weight:600;
  //   min-height: 40px;
  //   @media (min-width: 992px) {
  //           min-height: 40px;
  //         }
  //         @media (min-width: 1201px) and (max-width: 1599px) {
  //           min-height: 40px;
  //         }
  // }
  .p-name{
    height:20px;
    min-height: 20px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
  .pdt-description {
    height:120px;
    min-height: 120px;
    overflow:hidden;
      // @media (min-width: 992px) {
      //   min-height: 120px;
      // }
      // @media (min-width: 1201px) and (max-width: 1599px) {
      //   min-height: 120px;
      // }
  }

  .related-pdt-description {
    min-height: 120px;
      @media (min-width: 992px) {
        min-height: 120px;
      }
      @media (min-width: 1201px) and (max-width: 1599px) {
        min-height: 120px;
      }
  }
  .fleato {
    font-size: 1.3rem;
  }
  .fleato {
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    &:before {
      content: "[";
      font-size: 24px;
      font-weight: 700;
      position: absolute;
      top: -3px;
      color: #e6e6e6;
      left: 4px;
    }
    &:after {
      content: "]";
      font-size: 24px;
      font-weight: 700;
      position: absolute;
      top: -3px;
      color: #e6e6e6;
      right: -6px;
    }
  }
`;

export const ProductCardWrapper = styled.div`
  position: relative;
  width: 100% !important;
  border-radius: 15px;
  height: 240px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
  .pdt-like {
    position: absolute;
    display: flex;
    bottom: 5px;
    right: 7px;
    span {
      color: red;
    }
  }
  .pdt-auction {
    position: absolute;
    display: flex;
    bottom: 5px;
    left: 7px;
  }
`;

export const ProductInfo = styled.div`
  // .price {
  //   h4 {
  //     font-weight: 600;
  //     text-align: center;
  //     font-size: 1.1rem;
  //     margin: 0;
  //     span {
  //       display: block;
  //       font-size: 0.6rem;
  //       font-weight: 400;
  //       color: #ff7600;
  //       font-size: 0.5rem;
  //     }
  //   }
  // }
  // .offer {
  //   h4 {
  //     font-weight: 600;
  //     color: #29ac49;
  //     font-size: 1.1rem;
  //     margin-bottom: 0;
  //     svg {
  //       max-width: 18px;
  //       margin-right: 4px;
  //     }
  //   }
  //   p {
  //     display: flex;
  //     align-items: center;
  //     color: #000;
  //     margin-top: -5px;
  //     svg {
  //       max-width: 15px;
  //       margin-left: 5px;
  //       margin-right: 2px;
  //     }
  //   }
  // }
  // button:focus {
  //   box-shadow: none;
  // }
  // .btn.text-blue:hover {
  //   color: #29ac49;
  // }
`;

export const ProductSortWrapper = styled.div`
  margin-bottom: 10px;
  label {
    font-size: 0.9rem;
  }
  select {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;

    font-size: 0.9rem;
    border-radius: 4px;
  }
  @media (max-width: 425px) {
    .col-1 {
      width: 8%;
    }
    .col-11 {
      width: 88%;
    }
  }
`;
export const RefinementWrapper = styled.div`
  padding:10px 0;
  ul li {
    padding-bottom: 2px;
    color: #656565;
    display: flex;
    justify-content: space-between;
    span {
    }
    a {
      color: #656565;
      text-decoration: none;
      cursor: pointer;
    }
    a:hover {
      text-decoration: underline !important;
    }
  }
  input,
  input:focus {
    border: 1px solid #707070;
    outline: none;
  }
`;

export const PdtFilterWrapper = styled.div`
  &.visible {
    max-width: 100%;
    visibility: visible;
    opacity: 1;
    overflow: visible;
    transition: max-width 0.2s ease-in-out, visibility 0.6s, opacity 0.2s;
  }

  max-width: 0;
  opacity: 0;
  overflow-y: scroll;
  visibility: hidden;
  background-color: rgb(0 0 0 / 60%);
  z-index: 11;
  .close {
    position: absolute;
    right: -32px;
    top: 10px;
  }
  .pdt-wrapper {
    position: relative;
    width: 50%;
    background: #f9f9f9;
  }
  @media (max-width: 600px) {
    .pdt-wrapper {
      width: 60%;
    }
  }
  @media (max-width: 500px) {
    .pdt-wrapper {
      width: 70%;
    }
  }
`;

export const PdtDetailTableWrapper = styled.div`
  table {
    border-collapse: separate;
    border-spacing: 0 0.5em; 
  }
  td{
    vertical-align:baseline;
  }
`;

export const PdtDetailWrapper = styled.div`
  h3 {
    font-size: 24px!important;
    line-height: 32px!important;
    // margin-bottom: 16px;
  }
  h4 {
    font-size: 0.9rem;
    span {
      font-weight: 400;
    }
  }
  .grey-bg {
    background: #dbdbdb;
  }
  .list-steps {
    p {
      font-size: 0.85rem;
    }
  }
  .price,
  .fleato {
    // font-size: 1.3rem;
  }
  .fleato {
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    &:before {
      content: "[";
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      top: -3px;
      color: #e6e6e6;
      left: 4px;
    }
    &:after {
      content: "]";
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      top: -3px;
      color: #e6e6e6;
      right: -6px;
    }
  }
  .box {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #d3d2d2;
  }
  .earn-fleato {
    @media (min-width: 1300px) {
      display: flex;
      button {
        margin: 0;
        margin-left: 10px;
      }
    }
    @media (min-width: 992px) and (max-width: 1299px) {
      display: block;
      button {
        margin: 0;
        margin-top: 5px;
      }
    }
    @media (max-width: 991px) {
      display: flex;
      button {
        margin: 0;
        margin-left: 10px;
      }
    }
    @media (max-width: 450px) {
      display: block;
      button {
        margin: 0;
        margin-top: 5px;
      }
    }
  }
  @media (min-width: 992px) {
    .overflow-wrapper {
      // height: calc(80vh - 180px);
      // overflow-y: auto;
      // overflow-x: hidden;
      // scrollbar-width: thin;
    }
  }
  @media (min-width: 576px) {
    .w-sm-75 {
      width: 75% !important;
    }
  }
  @media (max-width: 768px) {
    margin-top:20px;
  }
`;
export const PdtWrapper = styled.div`
.show-marker li::marker{
  color:black;
}
.show-marker p,.artistData p{
  margin-bottom:0;
}
.review-progress{
  width:200px;
}

h1{
  font-size:28px;
}
.borderBottom{
  border-bottom:1px solid #dee2e6;
}
.priceText,
.buyOptions{
  padding: 20px 0px;
}

.auctionInfo{
  padding: 30px 0px;
  border-left: 1px solid #cdc0c0;
}

@media only screen and (min-width: 768px){
  .pdtShareButton{
    padding-right: 100px !important;
  }
  
.mt-md-m{
  margin-top:1.3rem;
}
}

.productLocationWrapper{
  display: grid;
  grid-template-columns: 50% 50%;
}
.productNFTWrapper{
  display: grid;
  grid-template-columns: 50% 50%;
}
.productNoNFTWrapper{
  display: grid;
  grid-template-columns: 100%;
}

@media only screen and (max-width: 1024px){
  .auctionInfo{
    border-left: 0;
  }
}
`;
export const CurrentRefinementsWrapper = styled.div`
  ul {
    list-style: none;
    list-style: none;
    // display: flex;
    color: #000;
    font-size: 0.9rem;
    li {
      padding-right: 15px;
    }
    .ais-CurrentRefinements-delete {
      margin-left: 8px;
      background: white;
      border: 0;
      font-size: 0.8rem;
    }
    .ais-CurrentRefinements-item {
      margin: 5px;
      color: black;
      padding: 5px;
    }
    .ais-CurrentRefinements-category {
      margin: 5px;
      color: #000;
      borderRadius: 15px !important;
      background-color: #fff;
      padding: 5px;
      border:2px solid #1478DD;
    }
    &.ais-CurrentRefinements-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }
  }
`;

export const BidModalWrapper = styled.div`
  background: #fff;
  h3 {
  }
  border-radius: 0px;
  padding: 20px 40px;
  .close {
    right: 10px;
    cursor: pointer;
    top: 10px;
  }
  table {
    border-color: #707070;
  }
`;
export const CustomizeModalWrapper = styled.div`
.list{ margin:20px 20px;
.img-wrapper{min-width:200px;width:200px;height:150px;position:relative;margin-right:20px}
@media(max-width:767px){
  .img-wrapper{width:100%;height:200px;margin-bottom:10px}

}
}
.border{
 border:1px solid #e3e3e3 !important;
}
.close{
top: 20px;
 right: 10px;
  background-color: #000000;
    width: 24px;
    height: 24px;
  @media(min-width:720px){


  }
}
.border-bottom{
  border-bottom:1px solid #e3e3e3 !important;
 }
.card-options{
&.active {
    border: 3px solid #1478DD !important;
}
  width:100%;
&:hover{
  &>div{background:rgba(0,0,0,.4);

}
}
height:250px;
  @media(min-width:720px){
  width:47%;
  }
  @media(min-width:920px){
    width:22%;
    }
}
.blue-bg{
  width:100%;
}
.btn-light{
background-color:#fff!important;
border:1px solid #d2d2d2;
}
.btn-primary{
background-color:#1478DD !important;

}
.form-check-input{
  border:1px solid #999 !important;

}
.w-md-50{
  .blue-bg{
    width:100%;
  }
  @media(min-width:720px){
  .blue-bg{
    width:50%;
  }
}
}
.table{
  margin-bottom:0 !important;
  border-spacing: 5px;
}
.table > :not(caption) > * > * {
border:none;

}
`;
export const VariationsListWrapper = styled.div`
  select,
  .accordianTitle {
    text-transform: Capitalize;
  }
  .list-bg-success {
    background-color: #35ff7c;
  }
`;
