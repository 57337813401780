import React, { useEffect, useContext, useState, useRef } from "react";
import {
  ProductWrapper,
} from "../../styles/product-style";
import Link from "next/link";
import { ProductsContext } from "../../contexts/ProductsContext";
import { formatAmount, productUrl, productDetailUrl, shorten, smartShorten, twoDecimals, zeroDecimals } from "../../util/util";
import useBuyNow from "../../hooks/product/useBuyNow";
import { useRouter } from 'next/router';
import animeLoading from "../../public/lottie/14117-crypto-loader.json";
import Lottie from "react-lottie";
import Countdown from 'react-countdown';
import moment, { now } from "moment";
import { UserContext } from "../../contexts/UserContext";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { tagBeginCheckout, tagViewItem } from "../../lib/events";
import { FleatoQuoteContext } from "../../contexts/FleatoQuoteContext";
import { Product, TypesenseProduct } from "../../models/product";
import { AiOutlineHeart, AiTwotoneTag } from 'react-icons/ai';
import { MdWarningAmber } from "react-icons/md";
import { RiAuctionFill } from 'react-icons/ri';
import { FiHexagon, FiPackage } from 'react-icons/fi';
import useProduct from "../../hooks/product/useProduct";
import useOrders from "../../hooks/order/useOrders";
import { OrderStatus } from "../../models/order";
import Image from "next/image";
import { Rating } from "react-simple-star-rating";
import { OrderContext } from "../../contexts/OrdersContext";
import UseWindowSize from "../../hooks/helper/useWindowSize";
import { isMobileOnly } from "react-device-detect";

const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span className="text-error">Auction ended</span>;
  } else {
    // Render a countdown
    if (days > 0 && hours > 0)
      return <span>({days}d {hours}h left)</span>
    else if (days > 0 && hours == 0)
      return <span>({days}d left)</span>
    else if (hours > 0 && minutes > 0)
      return <span>({hours}h {minutes}m left)</span>
    else if (hours > 0 && minutes == 0)
      return <span>({hours}h left)</span>
    else if (minutes > 0 && seconds > 0)
      return <span>({minutes}m {seconds}s left)</span>
    else if (minutes > 0 && seconds == 0)
      return <span>({minutes}m left)</span>
    else
      return <span>({seconds}s left)</span>
  }
};

const secondaryAction = (product) => {
  let message = ""
  if (product.isAuction) {
    if (moment(product.listingEndsOn).isBefore(moment()))
      message = "";
    else if (product.bids == 0)
      message = "bid now";
    if (product.bids == 1)
      message = `${product.bids} bid`;
    else if (product.bids > 1)
      message = `${product.bids} bids`;
    return (
      <span>{message} <Countdown date={new Date(product.listingEndsOn)} renderer={countdownRenderer} /></span>
    );
  } else {
    return <span>by {product.seller}</span>
  }
}

const deliveryHighight = (product: TypesenseProduct) => {
  return product.shippingMethods.join(", ").replace("shipping", "Shipping").replace("local_delivery", "Delivery").replace("local_pickup", "Pickup");
}


export const ProductCard = (props) => {
  const screenProps = UseWindowSize();
  const router = useRouter();
  const productPrice = useRef(null);
  const productImage = useRef(null);
  const { isAuthenticated, user, publicProfile, openLoginPopup, firebaseGuestSignIn } = useContext(UserContext);
  // console.log("public profile", publicProfile);
  const { incrementViewCount } = useContext(ProductsContext);
  const { fleatoForUSD } = useContext(FleatoQuoteContext);
  const { buyNow, fetchOrderNumber } = useBuyNow();
  const [isBuying, setIsBuying] = useState(false);
  const [loading, setLoading] = useState(false);
  const SwalAlert = withReactContent(Swal)
  const [ID, setID] = useState("");
  const activeProduct = (index: any) => {
    setID(index)
  }
  const product = props.hit as TypesenseProduct;

  const { fetchProduct } = useProduct();

  const { inCart } = useContext(OrderContext);
  const filterPurchases = () => {
    return inCart?.filter((s, index) =>
      s.status === OrderStatus.draft) ?? [];
  }
  const purchasesFiltered = filterPurchases() ?? [];

  const buy = async () => {
    //TODO: Add sku to product
    tagBeginCheckout(`${product.id}-${product.id}`, product.name, product.buyNowPrice, product.categories); //replace second product.id with product.sku
    if (!isAuthenticated) {
      const result = await SwalAlert.fire({
        icon: 'info',
        title: 'Almost there',
        html: '<p>Please sign-in to buy</p>',
        showCancelButton: true,
        // cancelButtonText: "Checkout as Guest",
        confirmButtonText: 'Login',
        confirmButtonColor: "#1478DD",
        footer: '<a href="/help">Why should I login?</a>'
      });
      if (result.isConfirmed) {
        openLoginPopup();
        return;
      } else {
        // await firebaseGuestSignIn();
        //continue with the checkout process
      }
    }
    setIsBuying(true);
    if (!!purchasesFiltered?.find(item => (item.productSku === product?.sku))) {
      router.push(`/checkout/${purchasesFiltered?.find(item => item.productSku === product?.sku).orderHeaderId}`);
    }
    else {
      const dbProduct = await fetchProduct(product.id);
      const orderHeaderId = await buyNow({ productId: product.productSku ?? product.id, variationSku: product.sku, quantity: 1 });
      // const on = await fetchOrderNumber(product.id);
      if (product?.isCustomizable) {
        router.push({ pathname: productDetailUrl(product), query: { "customize": true } })
      }
      else {
        if (orderHeaderId) router.push(`/checkout/${orderHeaderId}`);
      }
    }
  }

  useEffect(() => {
    if (product)
      incrementViewCount([product.id]);
  }, [product])

  let overAllRatings = product?.rating ?? [0, 0, 0, 0, 0];
  const totalReviews = (overAllRatings?.[0] + overAllRatings?.[1] + overAllRatings?.[2] + overAllRatings?.[3] + overAllRatings?.[4]);
  const avgRating = (overAllRatings?.[0] + overAllRatings?.[1] * 2 + overAllRatings?.[2] * 3 + overAllRatings?.[3] * 4 + overAllRatings?.[4] * 5) / totalReviews;

  return (
    <ProductWrapper key={product.id} onMouseEnter={() => activeProduct(product?.id)} //pass item.ID
      onMouseLeave={() => activeProduct("")} >
      <div className="pdt-cover">

        {loading &&
          <Lottie height={productImage?.current?.clientHeight ?? 340} width={80} options={{ loop: true, autoplay: true, animationData: animeLoading }} />
        }
        {!loading &&

          <>
            {isBuying &&
              <Lottie height={productPrice?.current?.clientHeight ?? 106} width={80} options={{ loop: true, autoplay: true, animationData: animeLoading }} />
            }
            {!isBuying &&
              <ul className="p-0">
                <li >
                  <div className="card-wrapper">
                    <Link href={productDetailUrl(product)} passHref>
                      <div
                        className="card cursor-pointer"
                        ref={productImage}
                        onClick={() => {
                          tagViewItem('explore-category', 'Explore Category', product.id, product.name, product.buyNowPrice, product.categories);
                          setLoading(true);
                        }}
                      >
                        <Image alt="" src={product?.image ? productUrl(product?.image, 400) : "/images/missing.jpg"} layout="fill" objectFit="cover" objectPosition={"right top"} quality={75} />
                      </div>
                    </Link>
                    <div className=" p-2 pdt-description cursor-pointer" onClick={() => {
                      tagViewItem('explore-category', 'Explore Category', product.id, product.name, product.buyNowPrice, product.categories);
                      setLoading(true);
                    }}>
                      <Link href={productDetailUrl(product)} passHref>
                        <p style={{ whiteSpace: 'nowrap', minHeight: 18, height: 18 }}>{product?.collection}</p>
                      </Link>

                      <div className="d-flex">
                        <Link href={productDetailUrl(product)} passHref>
                          <div className="col-8 col-md-8" onClick={() => {
                            tagViewItem('explore-category', 'Explore Category', product.id, product.name, product.buyNowPrice, product.categories);
                            setLoading(true);
                          }}>
                            <h3 className="p-description overflow-hidden" style={{ maxHeight: 30, height: 40 }}>{shorten(product.name, 45)}</h3>

                            <div className="">
                              {product?.minimumPrice > 0 && product?.minimumPrice < product?.buyNowPrice ? <p>Prints and Merch starting from ${twoDecimals(product.minimumPrice)}</p> : <p>By {`${(product?.artist ?? "  ").charAt(0).toUpperCase()}${(product?.artist ?? "  ").slice(1)}`}</p>}
                            </div>
                          </div>
                        </Link>
                        <div className="col-4 col-md-4 text-end">
                          {/* <p>Price</p> */}
                          {product.isCustomizable ? <h3>varies</h3> : product.msrpPrice > 0 && product.msrpPrice > product.buyNowPrice ? <><del style={{ textDecorationColor: 'red', fontSize: 12 }}>${twoDecimals(product.msrpPrice)}</del><h3>${twoDecimals(product.buyNowPrice)}</h3></> : <h3>${twoDecimals(product.buyNowPrice)}</h3>}
                        </div>
                      </div>
                      {avgRating > 0 &&
                        <div className="d-flex">
                          <div className="">
                            <Rating onClick={null} ratingValue={avgRating} size={18} fillColor={'#ffd700'} />
                          </div>
                          <div className="">
                            <p>({totalReviews})</p>
                          </div>
                        </div>}
                    </div>
                    <div
                      className="d-flex price-wrap "
                    >
                      {ID == product?.id ? (
                        <>
                          {product.status === "listed" &&

                            <div className="col-9 col-md-9 d-flex align-items-center " >
                              <ul className="list-unstyled w-100 d-flex align-items-center">
                                {(!isAuthenticated || !publicProfile || publicProfile.handle !== product.seller) &&
                                  <li>
                                    {product.availableQuantity == 0 ?
                                      <button
                                        className="btn white-bg rounded  m-0 p-0 text-error"
                                        style={{
                                          lineHeight: "10px", fontWeight: 600,
                                          fontSize: 12
                                        }}
                                        type="button"
                                      >
                                        Sold Out
                                      </button>
                                      :
                                      product.isAuction ?
                                        <Link href={isMobileOnly ? `/sv/${product?.productSku ?? product?.sku}` : `/p/${product?.productSku ?? product?.sku}`} passHref>
                                          <button
                                            className="btn white-bg rounded  m-0 p-0 text-blue"
                                            style={{
                                              lineHeight: "10px", fontWeight: 600,
                                              fontSize: 12
                                            }}
                                            type="button"
                                          >
                                            Bid Now
                                          </button>
                                        </Link>
                                        :
                                        <Link href={isMobileOnly && !product?.isCustomizable ? `/sv/${product?.productSku ?? product?.sku}` : `/p/${product?.productSku ?? product?.sku}${product?.isCustomizable ? "?customize=true" : ""}`} passHref>
                                          <button
                                            className="btn white-bg rounded  m-0 p-0 text-blue"
                                            style={{
                                              lineHeight: "10px", fontWeight: 600,
                                              fontSize: 12
                                            }}
                                            type="button"
                                            onClick={(e) => {
                                              if (product.isCustomizable) {
                                                tagViewItem('explore-category', 'Explore Category', product.id, product.name, product.buyNowPrice, product.categories);
                                                setLoading(true);
                                              } else {
                                                e.preventDefault();
                                                buy()
                                              }
                                            }}
                                          >
                                            {product.isCustomizable ? "Customize" : "Buy Now"}
                                          </button>
                                        </Link>
                                    }
                                  </li>
                                }

                                {(isAuthenticated && publicProfile?.handle === product.seller) &&
                                  <Link href={`/sell/${product?.productSku ?? product?.sku}`} passHref>
                                    <li>
                                      <button
                                        className="btn white-bg rounded  m-0 p-0 text-blue"
                                        style={{
                                          lineHeight: "10px", fontWeight: 600,
                                          fontSize: 14
                                        }}
                                        type="button"
                                      >
                                        Edit
                                      </button>
                                    </li>
                                  </Link>

                                }
                                <Link href={productDetailUrl(product)} passHref>
                                  <li>
                                    <button
                                      className="btn white-bg rounded  m-0 p-0 text-blue"
                                      style={{
                                        lineHeight: "10px", fontWeight: 600,
                                        fontSize: 14
                                      }}
                                      type="button"
                                      onClick={() => {
                                        tagViewItem('explore-category', 'Explore Category', product.id, product.name, product.buyNowPrice, product.categories);
                                        setLoading(true);
                                      }}
                                    >
                                      View
                                    </button>
                                  </li>
                                </Link>
                              </ul>

                            </div>
                          }
                          {/* <div className="col-3 col-md-3 text-end p-2">
                              <AiOutlineHeart size={20} color="#000000" />
                            </div> */}
                        </>
                      ) : (
                        <>
                          <div className="d-flex  col-6  col-md-6">
                            {product.availableQuantity > 0 &&
                              <div className="col-3 d-flex align-items-center p-2">
                                <span className="ps-2"><AiTwotoneTag size={20} color={'#1478DD'} /></span>
                              </div>}
                            {product.availableQuantity == 0 && <div className="col-3 d-flex align-items-center p-2">
                              <span className="ps-2"><MdWarningAmber size={20} color={'#f00'} /></span>
                            </div>}
                            {product.isAuction && <div className="col-3 d-flex align-items-center p-2">
                              <span className="ps-2"><RiAuctionFill size={20} color={'#5a5a5f'} /></span>
                            </div>}
                            {product.isNFT && <div className="col-3 d-flex align-items-center p-2">
                              <span className="ps-2"><FiHexagon size={20} color={'#20db89'} /></span>
                            </div>}
                            {
                              product.isPhysical &&
                              <div className="col-3 d-flex align-items-center p-2">
                                <span className="ps-2">
                                  <FiPackage size={20} color={'#1478DD'} />

                                </span>
                              </div>
                            }

                          </div>

                          {/* <div className="col-6 col-md-6 text-end p-2 ">

                              <AiOutlineHeart size={20} color={product.likes ? "red" : "gray"} />
                              <span style={{ verticalAlign: "middle" }}>{product.likes ? product.likes : ""}</span>
                            </div> */}

                        </>

                      )}
                    </div>
                  </div>
                </li>
              </ul>

            }
            {/* <div onClick={() => setLoading(true)} ref={productImage} >
              <div style={{
                paddingBottom: '10px'
              }}>
                <Link href={`/p/${product?.sku ?? product?.id}`} >
                  <ProductCardWrapper
                    style={{ backgroundImage: `url(${product.image ?? "/images/missing.jpg"})` }} onClick={() => like(product.id)} className="cursor-pointer"
                  >
                    {console.log(moment(product?.listing?.listingEndsOn).isAfter(moment()))}
                    {product?.isAuction && <div className="pdt-auction"  >
                      {moment(product?.listing?.listingEndsOn).isAfter(moment()) ?
                        <div className="position-relative">
                          <BsLightningChargeFill className={'lightning position-absolute '} style={{ left: -10, bottom: 0 }} size={10} color={'ffca28'} />
                          <BiAlarm size={22} className={'alarm '} onMouseOver={() => console.log("Id")} title={`Auction ends ${moment(product?.listing?.listingEndsOn).fromNow()}`} />
                          <BsLightningChargeFill className={'lightning position-absolute '} style={{ left: 20, bottom: 2 }} size={10} color={'ffca28'} />
                        </div>
                        :
                        <div className="position-relative">
                          <RiZzzLine size={18} className={'snooze position-absolute '} color={"#1478DD"} style={{ bottom: 15, left: 4 }} />
                          <BiAlarmSnooze size={22} style={{ backgroundColor: "#fff" }} onMouseOver={() => console.log("Id")} title={`Auction ends ${moment(product?.listing?.listingEndsOn).fromNow()}`} />
                        </div>
                      }
                    </div>} */}
            {/* <div className="pdt-like" >

                      <FiHeart size={22} color={product.likes ? "red" : "gray"} />
                      <span className="ps-1">{product.likes ? product.likes : ""}</span>
                    </div> */}
            {/* </ProductCardWrapper>
                </Link>
              </div>
              <table>
                <tr>
                  <td>
                    <p>Tag Goes Here</p>
                  </td>
                  <td>
                    <p>Price</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h3 className="text-black pdt-name">{smartShorten(product.name)}</h3>
                  </td>
                  <td>
                    <div className="price">
                      <h3>${twoDecimals(product.buyNowPrice)}</h3>
                    </div>
                  </td>
                </tr>
              </table> */}
            {/* <Link href={`/p/${product?.sku ?? product?.id}`} >
                <div className="pdt-description py-2 cursor-pointer">
                  <p className="mb-0 " style={{ textTransform: "capitalize" }}>{deliveryHighight(product)}</p>
                </div>
              </Link> */}
            {/* <div>
                <>
                  <div className=" d-flex align-items-center justify-content-between" >
                    <div>
                      <button
                        className="btn white-bg rounded bold m-0 p-0 text-blue"
                        style={{ lineHeight: "10px" }}
                        type="button"
                      >
                        Buy Now
                      </button>
                      <button
                        className="btn white-bg rounded bold m-0 p-0 text-blue"
                        style={{ lineHeight: "10px" }}
                        type="button"
                      >
                        View
                      </button>
                    </div>
                    <div className="pdt-like" >
                      <FiHeart size={22} color={product.likes ? "red" : "gray"} />
                      <span className="ps-1">{product.likes ? product.likes : ""}</span>
                    </div>
                  </div>


                </>

              </div>
            </div>
          </> */}
          </>
        }

        {/* 
        {!isBuying &&
          <ProductInfo ref={productPrice}>
            <Link href={`/p/${product?.sku ?? product?.id}`} >
              <div className="d-flex justify-content-between align-items-center cursor-pointer" style={{ height: 50 }} >
                <div className="price">
                  <h4>${twoDecimals(product.buyNowPrice)}</h4>
                </div>
                <div className="d-flex flex-column offer">

                  <p>
                    + You earn
                    <ReactSVG src={logo.src} alt="Logo" />&#160;
                    {zeroDecimals(fleatoForUSD(product.buyNowPrice * (buyerRewards?.basePercent ?? 9) / 100, 0))}
                  </p>
                </div>
              </div>
            </Link>
            {product.status === "listed" &&
              <div className="d-flex mt-3 justify-content-between">
                {(!isAuthenticated || !publicProfile || publicProfile.handle !== product.seller) &&
                  <button className="btn-ghost" onClick={buy}>
                    Buy Now
                  </button>
                }
                {(isAuthenticated && publicProfile?.handle === product.seller) &&
                  <Link href={`/sell/${product.id}`} >
                    <button className="btn-ghost" >
                      Edit
                    </button>
                  </Link>
                }

              </div>
            }
            {product.status === "bought" &&
              <div className="d-flex mt-3 justify-content-between">
                <button className="btn white-bg text-white py-0 px-3 font-sm" >

                </button>
                <button className="btn ps-2 p-0 text-blue">Sold</button>
              </div>
            }
          </ProductInfo>
        } */}
      </div>
    </ProductWrapper >
  )
}

