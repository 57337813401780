import { useState } from 'react';
import "firebase/auth";
import { doc, setDoc, increment } from "firebase/firestore";
import { db } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'

const PRODUCT = "product";
const PRODUCT_PUBLIC = "public";
const PRODUCT_STATS = "stats";

export default function useViewTracking() {

  const [viewedProductIds, setViewedProductIds] = useState<string[]>([]);

  const incrementViewCount = async (productIds: string[]) => {
    // const newIds = productIds.filter(id => !viewedProductIds.includes(id));
    // // console.log("Incrementing view counts for", newIds);
    // await Promise.allSettled(
    //   newIds.map(async (id) => {
    //     const counterRef = doc(db, `${PRODUCT}/${id}/${PRODUCT_PUBLIC}/${PRODUCT_STATS}`);
    //     await setDoc(counterRef, {productId: id, views: increment(1)}, { merge: true })
    //   })
    // );
    // setViewedProductIds(prev => Array.from(new Set(prev.concat(newIds))));
  }

  const like = async (productId: string | undefined) => {
    try{
    const counterRef = doc(db, `${PRODUCT}/${productId}/${PRODUCT_PUBLIC}/${PRODUCT_STATS}`);
    await setDoc(counterRef, {productId, likes: increment(1)}, { merge: true })
  }
catch(err) {
  // console.log({setShortUrlData: e});      
  Sentry.captureException(err);console.log(err)      
}
  }

  return {incrementViewCount, like };
}